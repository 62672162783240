$("#button-inquire").on("click", function(e) {

    ga('send', 'event', {
        eventCategory: 'Order Link',
        eventAction: 'click',
        eventLabel: "Poptat gramofon, ukaž modal okno",
        transport: 'beacon'
    });

});
