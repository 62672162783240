'use strict'

jQuery(document).ready(function() {

    var $win = $(window);
    var $body = $("body");
    var $document = $(document);
    var $light_bg = $(".light-bg");
    var $light_bg_close = $light_bg.find(".light-bg__close");
    var $mobile_menu_toggle = $(".mobile-menu-toggle");
    var $price_placeholder = $('.price-placeholder-component');
    var $select_attribute_component = $('.select-attribute-component');
    var $main_menu = $(".main-menu-nav");

    var $language_switch = $(".language-switch");

    var $loaderBg = $(".loader-bg");
    var $loader_links = $(".link-loader");

    var cache = {};

    var screen_xs = 480;
    var screen_sm = 768;
    var screen_md = 992;
    var screen_lg = 1200;

    $loader_links.on("click", function() {
        $loaderBg.removeClass("hidden");
    });

    $menu_item_search_link = $(".main-menu-user-actions-item-search");

    function app_init() {

        if ($win.width() < screen_md) {
            $language_switch.find("a").first().on("click", function() {
                var $this = this;

                $language_switch.find(".main-menu-nav-submenu").toggle();

                return false;
            });
        }

        $("form.data-validate").each(function() {
            new FormValidation(this, {});
        });

        $document.keyup(function(e) {
            if (e.keyCode == 27) {
                console.log("first here");
                if (!$light_bg.hasClass("hidden")) { 
                    console.log("second here");
                    $light_bg_close.trigger("click");

                }
            }
        });

        $light_bg_close.on("click", function(e) {

            $document.trigger("lightbg:close");

            if (cache.hasOwnProperty("activeModal") && cache.activeModal !== null) {
                cache.activeModal.trigger("modal:close");
            }

            var newsletter_focused = $(".newsletter-component.focused");

            if (newsletter_focused.length > 0) {
                newsletter_focused.removeClass("focused");
                newsletter_focused.find(".input-validator").addClass("hidden");
            }
            
            return false;
        });

        $(".newsletter-component").each(function() {

            $this = $(this);
            $input = $this.find("input");

            $input.on("focus", function() {
                $this.addClass("focused"); /* a simple way to prevent toggling the class after another focus event */
                $document.trigger("lightbg:open");

            });

        });

        $(".nav-tab").each(function() {

            var $this = $(this);
            var tab_content = $($this.attr("data-tab-content")).find(".tab-pane");

            $this.on("click", function() {
                
                tab_content.addClass("active in");

            });

        });

        $(".faq-item").each(function() {

            var $this = $(this);
            var $title = $this.find("h3");
            var $faq_item_content = $this.find(".faq-item-content");

            $title.on("click", function() {
                $this.toggleClass("open");
                $faq_item_content.toggleClass("hidden");
            });

        });


        $price_placeholder.on("form:price.recalculate", function() {

            var sum = 0;

            $select_attribute_component.each(function() {

                sum += parseFloat($(this).find("select").val());

            });

            $price_placeholder.text(sum);

        });


        $('.button-select').each(function() {

            var $this = $(this);
    
            var select_text = $this.attr("data-select-text");
            var selected_text = $this.attr("data-selected-text");
            var selected_text_hover = $this.attr("data-selected-text-hover");

            var text_placeholder = $this.find( $this.attr("data-text-placeholder") );

            var check_text = function() {
     
                if ( $this.hasClass("selected") ) {

                     text_placeholder.text( selected_text );

                } else {

                    text_placeholder.text( select_text );

                }

            };

            check_text();

            $this.on("button:checkText", function(e) {

                check_text();

            });

        });


        $select_attribute_component.each(function() {

            var $this = $(this);
            var $select = $this.find(".select-picker");
            var $placeholder = $this.find($select.attr("data-placeholder"));
            var $link_open_modal = $this.find(".select-attribute-open-modal");
            var $modal = $( $this.attr("data-modal-id") );
            var $modal_selection = $modal.find("._modal-table-list");
            var $modal_button_select = $modal_selection.find('.button-select');

            var is_custom_select = $this.hasClass("select-attribute-custom-component");

            if (is_custom_select) {
                var $input_radio_custom = $this.find(".input-radio-custom");
            }

            $this.on("form:select-component.regenerate", function() {

                $select.trigger("change");

            });

            $link_open_modal.on("click", function() {
 
                $modal.trigger("modal:open");
            
                return false;

            });

            $select.on("change", function(e, args) {


                $placeholder.html($select.val());
                $price_placeholder.trigger("form:price.recalculate");

                
                // the change initiated from the select, therefore regenerate buttons within the modal
                // for custom selects, regenerate their values based upon the real html <select>

                var $selectElDOM = $select[0];
                var optionId = $selectElDOM.options[$selectElDOM.selectedIndex].getAttribute("id");

                if (typeof e.isTrigger == "undefined" || args == "triggeredFromCustomSelect") {
                    var $relatedButton = $modal.find('[data-option-reference="#'+optionId+'"]');
                    $relatedButton.trigger("click", ['triggeredFromSelect']);
                }


                if (is_custom_select && (typeof e.isTrigger == "undefined" || args == "triggeredFromModalButtonSelect")) {
    
                    var $relatedCustomOption = $this.find('[data-select-option-id="#'+optionId+'"]');
                    $relatedCustomOption.trigger("click", "triggeredFromSelect");
                }

            });

            $modal_button_select.on("click", function(e, args) {

                var $thisTarget = $(e.currentTarget);
                
                if ( ! $thisTarget.hasClass("selected") ) {

                    // deselect the selected button
                    $selected = $modal_button_select.not($thisTarget).filter(".selected");
                    $selected.removeClass("selected");
                    $selected.trigger("button:checkText");

                    // selected the current button
                    $thisTarget.addClass("selected");
                    $modal_button_select.trigger("button:checkText");

                    if (typeof args == "undefined") {
                        
                        // the click purely triggered by the button itself
                        // trigger select element to change

                        // get DOM reference to the linked select > option element
                        $optionReferenceVal = parseFloat($($thisTarget.attr("data-option-reference")).attr("value"));
                        
                        // NOTE: <select> element doesn't trigger change event, if the value is changed by javascript
                        $select.val($optionReferenceVal).trigger("change", ["triggeredFromModalButtonSelect"]);                    

                    }

                } 

                return false;
            }); 

            if (is_custom_select) {
                
                $input_radio_custom.on("click", function(e, args) {
      

                    var $targetEl = $(e.currentTarget);

                    if(!$targetEl.hasClass("selected")) {
                        $input_radio_custom.filter(".selected").removeClass("selected");
                        $targetEl.addClass("selected");

                        if (typeof e.isTrigger == "undefined") {

                            $select.find("options").prop("selected", false); // deselect all options
                            var thisOption = $($targetEl.attr("data-select-option-id"));
                            thisOption.prop("selected", true);
                            $select.trigger("change", ["triggeredFromCustomSelect"]);

                        }
                        
                    }

                });


            }

        });

  
        $('[data-component="modal"]').each(function() {

            var $this = $(this);
            var $btn_close = $this.find("._modal-close");
            var $bg = $($this.attr("data-modal-bg"));

            $btn_close.on("click", function() {

                $this.trigger("modal:close");

                return false;

            });

            $this.on("modal:close", function() {
                cache.activeModal = null;
                $this.addClass("hidden");
                $document.trigger("lightbg:close");
                

            });

            $this.on("modal:open", function() {
                cache.activeModal = $this;
                $this.removeClass("hidden");
                $document.trigger("lightbg:open");
            });     


        });

        $('.image-gallery').each(function() {
            
            var $this = $(this);
            var $product_picture_main = $this.find(".item-product-picture-main");
            var $product_picture_main_img = $product_picture_main.find("img");
            var $product_image_previews = $this.find(".product-image-previews");
            var $product_image_previews_items = $product_image_previews.find("a");
            var $link_open_gallery = $this.find(".icon-open-gallery");
            var $gallery = $( $this.attr("data-gallery") );

            $product_image_previews_items.on("click", function() {
                console.log("sdsds");
                $product_picture_main_img.attr("src", $(this).attr("href"));
                return false;
            });

            $link_open_gallery.on("click", function() {
                $document.trigger("lightbg:open");
                $gallery.addClass("active");
                return false;
            });


        });


        $(".scroll-to").click(function (){

            var $target = $($(this).attr("data-target"));
            console.log($target);
            $('html, body').animate({
                scrollTop: $target.offset().top - 60
            }, 1000);

            return false;

        });

        function regenerateOrderModal() {
            
            $select_attribute_component.each(function() {

                var $this = $(this);
                var $select = $this.find("select");
                var $selectElDOM = $select[0];

                var data_modal_section_id = $this.attr("data-modal-order-section-id");
                var $modal_section = $(data_modal_section_id);

                var $selectedOption = $selectElDOM.options[$selectElDOM.selectedIndex];
                var optionData/*<Object>*/ = JSON.parse($selectedOption.getAttribute("data-options"));
                var optionId = $selectedOption.getAttribute("id");

                var $selectedBtn = $('[data-option-reference="'+optionId+'"]');

                $modal_section.find(".price-placeholder").html(optionData.value);
                $modal_section.find(".title-placeholder").html(optionData.title);
                $modal_section.find(".modal-order-section_desc-text").html(optionData.description);
                $modal_section.find(".order-image").attr("src", optionData.imgSrc);

                

            });

            var productDetailPrice = $("#product-detail-price").text();
            $("#modal-order-turntable").find(".item-product-price").find(".price-placeholder").html(productDetailPrice);

        }

        $(".open-modal").on("click", function() {

            var elAttr = $(this).attr("data-modal-id");
            $modal = $( elAttr );
            $modal.trigger("modal:open");

            if ("#modal-order-turntable" === elAttr) {
                regenerateOrderModal();
            }


            return false;

        });        

        $document.on("lightbg:open", function() {
            $light_bg.removeClass("hidden");
        });

        $document.on("lightbg:close", function() {
            $light_bg.addClass("hidden");
        });

        $mobile_menu_toggle.on("click", function(e) {

            $body.toggleClass("mobile-menu-on");

        });

        $(".main-header").sticky({topSpacing:0, zIndex: 2});


        $(".page-nav").each(function() {

            var $this = $(this);

            if ($win.width() >= screen_md) {

                 $(".page-nav").sticky({topSpacing:60, zIndex: 1});
                
            }

        });
        

        $owls = $('.owl-carousel').owlCarousel({
            items:1,
            navigation: false,
            dots: false,
            autoplay: true,
            lazyLoad:true,  
            loop:true,
            margin:0,
            onInitialized: function() {
                if ($(".owl-item.active video", this.$element).length) {
                    $(".owl-item.active video", this.$element)[0].play();
                   
                    $(".owl-item.active video", this.$element).on('ended', function() {
                       
                    });
                }
            }         
        });


        $owl_modal = $('.owl-carousel-modal-list').owlCarousel({
            items:3,
            navigation: false,
            dots: false,
            loop:false,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true,
                    stagePadding: 0
                },
                screen_sm:{
                    items:2,
                    stagePadding: 25
                },
                screen_md:{
                    items:1,
                    stagePadding: 50
                }
            }               
        });        

        $owls.on('initialized.owl.carousel', function(event) {
            $(event.target).find("video")[0].play();
        });

        $owls.each(function() {

            var $this = $(this);
   
            $this.parents(".slider").find('.slider-nav-left').click(function () {
                $this.trigger('prev.owl.carousel');
                $this.trigger('stop.owl.autoplay');
                return false;
            });

            $this.parents(".slider").find('.slider-nav-right').click(function () {
                $this.trigger('next.owl.carousel');
                $this.trigger('stop.owl.autoplay');
                return false;
            });

        });

        $owl_modal.each(function() {

            var $this = $(this);
   
            $this.parents(".slider-modal").find('.slider-nav-left').click(function () {
                $this.trigger('prev.owl.carousel');
                $this.trigger('stop.owl.autoplay');
                return false;
            });

            $this.parents(".slider-modal").find('.slider-nav-right').click(function () {
                $this.trigger('next.owl.carousel');
                $this.trigger('stop.owl.autoplay');
                return false;
            });

        });  


  
    };


    app_init();

    var myLazyLoad = new LazyLoad({
        elements_selector: ".bg-image, [data-original]"
    });

    // init default values of the form
    $select_attribute_component.trigger("form:select-component.regenerate");

    $loaderBg.addClass("hidden");


  $(".item-social-link-share").on('click', function(e) {

    var $this = $(this);
    e.preventDefault();
    var intWidth = intWidth || '500';
    var intHeight = intHeight || '400';
    var strResize = 'yes';
    var strTitle = ((typeof $this.attr('title') !== 'undefined') ? $this.attr('title') : 'Social Share');
    var strParam = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize;
    var objWindow = window.open($this.attr('href'), strTitle, strParam).focus();
  });

});
